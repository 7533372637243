













































































import { Component, Prop, Mixins } from 'vue-property-decorator'
import TrashCan16 from '@carbon/icons-vue/es/trash-can/16'
import Edit16 from '@carbon/icons-vue/es/edit/16'
import isEmpty from 'lodash/isEmpty'

import GenericModalDelete from '@/partials/modals/generic/delete.vue'
import FlashMessageMixin from '@/mixins/flashMessageMixin'
import PermissionsMixin from '@/mixins/permissionsMixin'

@Component({
  components: {
    TrashCan16,
    Edit16,
    GenericModalDelete
  }
})
export default class EventSpeakerActivitiesTable extends Mixins(FlashMessageMixin, PermissionsMixin) {
  @Prop({ required: true, type: Array }) activities!: Array<any>
  @Prop({ required: true, type: String }) speakerId!: string
  @Prop({ required: true, type: String }) eventId!: string
  deleteModalIsOpened = false
  selectedActivity: any = {}

  postSubmit () {
    this.deleteModalIsOpened = false

    const flashMessage: flashMessage = {
      message: 'A Programação foi removida com sucesso.',
      type: 'success'
    }

    this.setFlashMessage(flashMessage)
    this.$emit('deleted', this.selectedActivity.id)
  }

  get deleteUrl () {
    return isEmpty(this.selectedActivity) ? '' : `/event/${this.eventId}/activity/${this.selectedActivity.id}`
  }
}
